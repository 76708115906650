import React, { useState } from 'react';
import { Outlet } from "react-router-dom";
import { MainPage } from "./SidebarStyle";
import { ToastContainer } from "react-toastify";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { NavHeader } from "./NavHeader";

const MOBILE_VIEW = window.innerWidth < 468

export default function MainLayoutV2() {

  const [displaysidebar, setDisplaySidebar] = useState(!MOBILE_VIEW)
  const handleSidebarDisplay = (e) => {
    e.preventDefault()
    if (window.innerWidth > 468)
      setDisplaySidebar(!displaysidebar)
    else 
      setDisplaySidebar(false)
  }

  return (
    <VWrapper>
      <NavHeader />
      <HWrapper>
        <Sidebar 
          displaysidebar={displaysidebar} 
          handleSidebarDisplay={handleSidebarDisplay} />
        <MainContainer displaysidebar={displaysidebar} />
      </HWrapper>
    </VWrapper>
  )
}

export const VWrapper = (props) => <div style={{
  display: 'flex',
  flexFlow: 'column',
  height: '100vh',
}} {...props} />

export const HWrapper = (props) => <div style={{
    display: 'flex',
    // flexFlow: 'row',
    height: '100vh',
  }} {...props} />

export const MainContainer = ({displaysidebar}) => {
  return (
    <MainPage displaysidebar={displaysidebar}>
      {/* to place footer to the bottom of view */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - var(--nav-header-height) - var(--nav-footer-height) - var(--main-container-padding))',
      }}>
        <div style={{
          flex: 1,
          paddingBottom: 'var(--nav-footer-height)', // reserve space for the footer
        }}>
          <ToastContainer hideProgressBar={true} draggable={false} />
          {/* <TopBoilerPlate /> */}
          <Outlet />
        </div>
        <div style={{
          // position: 'absolute',
          bottom: '0',
          width: '100%',
          height: 'var(--nav-footer-height)',
        }}>
          <Footer />
        </div>
      </div>
    </MainPage>
  )
}