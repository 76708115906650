import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { SessionContext } from "../App"
import { dvSrv } from "../App"
import { Button, Modal } from "react-bootstrap"
import { TeamSelector } from "./Team"
import { useTeamAndUser } from "../hook/DataProvider"
import { PaginationTable } from "./Pagination"
import { toast } from "react-toastify"
import { TimeStringForTimeZone } from "./Setting"
import ReactJson from "@microlink/react-json-view"

// Common convenient function
export const GetDeviceSNWithTag = (serial, devices) => {
    const device = serial && serial.trim() !== "" && devices && devices.find((item) => item.Serial === serial)
    const deviceTag = (device && device.NameTag && device.NameTag.trim() !== "") ? device.NameTag.trim() : ""
    return deviceTag === "" ? serial : (serial + " | " + deviceTag)
}

export function Devices({account_uuid}) {
  const session = useContext(SessionContext)
  const [showDeviceModal, setShowDeviceModal] = useState(false)
  const [showDeviceImportModal, setShowDeviceImportModal] = useState(false)
  const [apiUrl, setApiUrl] = useState("") 
  const [flag, setFlag] = useState(false)
  const refresh = () => {
    setFlag(!flag)
  }

  const isSuperAdmin = session.data && session.data.AccountID === 1 && 
    account_uuid && account_uuid !== session.data.Account.UUID
  const importUrl = isSuperAdmin ? `/api/device/${account_uuid}/import` : '/api/device/import'

  useEffect(() => {
    let path = "/api/device/self"
    // for deepvinOnly access
    if (isSuperAdmin) {
      path = `/api/device/account/${account_uuid}`
    }
    setApiUrl(path)
  }, [flag])

  return (
  <>
    <DeviceTableWithPagination apiUrl={apiUrl} refresh={refresh} repaging={flag}/>
    <hr />
    <div>
      <button class="btn btn-outline-secondary me-3" id="btn-import-device" onClick={()=>setShowDeviceImportModal(true)}>Import Devices</button>
      <button class="btn btn-outline-secondary me-3" onClick={()=>setShowDeviceModal(true)}/*onClick={handleCreate}*/>Add Device</button>
    </div>
    {<DeviceImportCsvModal show={showDeviceImportModal} setShow={setShowDeviceImportModal}
      apiUrl={importUrl} formTitle={'Import Devices (.csv)'} 
      fileFormat={'SerialNumber,SSID,Password,NameTag,Description'} refresh={refresh}/>}
    {<DeviceAddModal show={showDeviceModal} setShow={setShowDeviceModal} refresh={refresh} account_uuid={account_uuid} session={session}/>}
  </>
  )
}

export const DeviceImportCsvModal = ({show, setShow, apiUrl, formTitle, fileFormat,refresh}) => {
  const session = useContext(SessionContext)
  const [selectedFileList, setSelectedFileList] = useState()

  const onFileChange = (e) => {
    setSelectedFileList([...e.target.files])
  }
 
  const onHide = () => setShow(false)
  const handleSubmit = () => {
    if (!selectedFileList || selectedFileList.length ===0){
      return
    }

    const formData = new FormData()
    // formData.append("device", "A00C383") // TODO
    selectedFileList.forEach((file, i) => {
      formData.append(`file-${i}`, file, file.name)
    })
    
    axios.post(dvSrv + apiUrl, formData, {withCredentials: true})
    .then(response => {
      //setShow(false)
      toast.success("csv file imported successfully!")
      refresh()
    })
    .catch(error => {
      console.error("Error: ", error)
      toast.error(error.response.data)
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })

    setShow(false)
    //refresh()
  }

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>{formTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h6>Expected csv file format: {fileFormat} </h6> */}
        <h5>CSV (comma-separated value) file template: </h5>
        <div style={{ fontSize: "12px", textAlign: "left" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid", padding: "2px" }}>SerialNumber</th>
                <th style={{ border: "1px solid", padding: "2px" }}>SSID</th>
                <th style={{ border: "1px solid", padding: "2px" }}>Password</th>
                <th style={{ border: "1px solid", padding: "2px" }}>NameTag</th>
                <th style={{ border: "1px solid", padding: "2px" }}>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid", padding: "2px" }}>UEA0BA06</td>
                <td style={{ border: "1px solid", padding: "2px" }}>UET-UEA0BA06</td>
                <td style={{ border: "1px solid", padding: "2px" }}>12345678</td>
                <td style={{ border: "1px solid", padding: "2px" }}>M1</td>
                <td style={{ border: "1px solid", padding: "2px" }}>Medic 1</td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr />
        <div class="input-group-lg">
          <input class="form-control" type="file" onChange={onFileChange} 
            id="formImportFile" accept=".csv" />
          {/* {selectedFileList && 
          <button class="btn btn-info" onClick={onFileChange}>Select users file</button>
          } */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Submit</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

const _columns = [
  { key: 'name_tag', label: 'Name Tag', jfield:'NameTag', sortable: true, searchable: true },
  { key: 'serial', label: 'Serial Number', jfield:'Serial', sortable: true, searchable: true },
  { key: 'ss_id', label: 'SSID', jfield:'SSID', sortable: true, searchable: false  },
  { key: 'password', label: 'Password', jfield:'Password', sortable: false, searchable: false },
  { key: 'team_id', label: 'Team', jfield:'TeamID', sortable: true, searchable: false },
  { key: 'description', label: 'Description', jfield:'Description', sortable: true, searchable: true },
  { key: 'locked', label: 'Locked', jfield:'Locked', sortable: false, searchable: false },
  { key: 'created_at', label: 'Added At', jfield:'CreatedAt', sortable: true, searchable: true },
  { key: 'action', label: 'Action', jfield:'NA', sortable: false, searchable: false },
  // Add more columns as needed
]

export const DeviceTableWithPagination = ({apiUrl, refresh, repaging}) => {
//   if (devices)
  return (
    <PaginationTable apiUrl={apiUrl} columns={_columns} sortbycol='name_tag' refresh={refresh} repaging={repaging} ItemComponent={DeviceTab}/>
  )
}

export const DeviceTable = ({devices, refresh}) => {

  //   if (devices)
    return (
      <table class="table table-hover">
      <thead>
      {_columns.map((column) => <th key={column.key}>{column.label}</th> )}
        {/* <th>Serial Number</th> 
        <th>SSID</th>
        <th>Password</th>
        <th>Name Tag</th>
        <th>Team</th>
      <th>Description</th> 
        <th class="text-center">Locked</th> 
      <th>Action</th> */}
      </thead>
      <tbody>
        {devices && devices.map(device => <DeviceTab device={device} refresh={refresh}/>)}
      </tbody>
    </table>
    )
  }

export const DeviceTab = ({item, refresh}) => {
    const session = useContext(SessionContext)
    const [teams, users] = useTeamAndUser()
    const [data, setData] = useState(item)
    const [edit, setEdit] = useState(false)

    const handleEdit = (e) => {
      setEdit(!edit)
    }
  
    const handleValueChange = (field, val) => {
        setData({...data, [field]: val})
    }
  
    const handleDelete = (e) => {
        if (window.confirm("Are you sure you want to delete this device? Once deleted, it can't upload videos to deepvin.com!")){
            axios.delete(dvSrv + `/api/device/${item.Serial}`, {withCredentials: true})
            .then(({data}) => {
                //setData(data)
                refresh()
            })
            .catch(error => {         
                if (error.response.status === 401) {
                session.setData(null)
            } else {console.error("Error: ", error)} } )
        }

    }

    const handleAssign = (e) => {

      axios.post(dvSrv + `/api/device/${item.Serial}`, {
//        ID: data.ID,
        NameTag: data.NameTag,
        Serial: data.Serial,
        SSID: data.SSID,
        Description: data.Description,
        Password: data.Password,
        Locked: data.Locked,
        TeamID: data.TeamID,
      }, {withCredentials: true})
      .then(({data}) => {
        setEdit(false)
        setData(data)
        refresh()
      })
      .catch(error => { 
        console.error("Error: ", error) 
        toast.error(error.response.data.error)
      } )
    }
  
    const handleCancel = (e) => {
      setData(item)
      setEdit(false)
    } 
    const team = data && data.TeamID && teams && teams.find((item) => item.ID === data.TeamID)
    if (item)
    return (
      <>
      <tr>
        <td>
            {edit
            ? <input type="text" class="form-control-sm" value={data.NameTag} placeholder="NameTag" aria-label="nametag" 
                onChange={(e) => handleValueChange("NameTag", e.target.value)}/>
            : item.NameTag
            }
        </td>
        <td>
            {edit
            ? <input type="text" class="form-control-sm" value={data.Serial} placeholder="SerialNumber" aria-label="serialnumber" 
                onChange={(e) => handleValueChange("Serial", e.target.value)}/>
            : item.Serial
            }
        </td>
        <td>
            {edit
            ? <input type="text" class="form-control-sm" value={data.SSID} placeholder="UET-[SerialNumber]" aria-label="ssid" 
                onChange={(e) => handleValueChange("SSID", e.target.value)}/>
            : item.SSID
            }
        </td>
        <td>
            {edit
            ? <input type="text" class="form-control-sm" value={data.Password} placeholder="Password" aria-label="Password" 
                onChange={(e) => handleValueChange("Password", e.target.value)}/>
            : item.Password
            }
        </td>
        <td>
          {edit
          ? <TeamSelector teams={teams} data={data} setData={setData} />
          : <span>{team ? team.Name : null}</span>
          }
        </td>
        <td>
            {edit
            ? <input type="text" class="form-control-sm" value={data.Description} placeholder="Description" aria-label="description" 
                onChange={(e) => handleValueChange("Description", e.target.value)}/>
            : item.Description
            }
        </td>
        <td class="text-center">
          <input class="form-check-input mt-0" type="checkbox" style={{ border: '1px solid darkgrey' }} checked={data.Locked}  
          onChange={(e) => handleValueChange("Locked", e.target.checked)} disabled={!edit}/>
        </td>
        <td>{TimeStringForTimeZone(session, data.CreatedAt)}</td>
        <td>
            {edit
            ? <div class="btn-group" role="group">
                <button class="btn btn-sm btn-success py-0" type="button" onClick={handleAssign}>Submit</button>
                <button class="btn btn-sm btn-warning py-0" type="button" onClick={handleCancel}>Cancel</button>
            </div>
            : <div class="btn-group" role="group">
                <button class="btn btn-outline-secondary btn-sm py-0" onClick={handleEdit}>Edit</button>
                <button class="btn btn-outline-secondary btn-sm py-0" onClick={handleDelete} disabled={edit}>Delete</button>
            </div>
            }
        </td>
      </tr>
      {/* <ReactJson src={{item}} /> */}
      </>
    )
}

const DeviceAddModal = ({show, setShow, refresh, account_uuid, session}) => {
  const [teams, users] = useTeamAndUser()
  const [devName, setDevName] = useState("")
  const [devDesc, setDevDesc] = useState("")
  const [devSN, setDevSN] = useState("")
  const [ssid, setSSID] = useState("")
  const [devMPW, setDevMPW] = useState("")
  const [locked, setLocked] = useState(false)
  const [data, setData] = useState({TeamID: 0})

  const isSuperAdmin = session.data && session.data.AccountID === 1 && 
    account_uuid && account_uuid !== session.data.Account.UUID
  const addUrl = isSuperAdmin ? `/api/device/${account_uuid}/add` : '/api/device/add'

  const addDevice = (name, sn, desc, mpw) => {
    axios.post(dvSrv + addUrl, 
      {
        NameTag: name,
        Serial: sn,
        SSID: ssid,
        Description: desc,
        Password: mpw,
        Locked: locked,
        TeamID: data.TeamID,
      }, 
      {withCredentials: true})
    .then(response => {
          refresh()
      }).catch(error => {
        toast.error(error.response?.data?.error)
        console.error("Error adding new device: ", error)
      })
    }
  
  const onHide = () => setShow(false)
  const handleSubmit = () => {
    let serial = devSN.trim()
    let inSSID = ssid.trim()
    let inPwd = devMPW.trim()
    if (serial === "" || inSSID === "" || inPwd === ""){
      alert("The Serial Number, SSID or Password can not be empty!")
      return
    }

    addDevice(devName, serial, devDesc, inPwd)
    setShow(false)
    //refresh()
  }

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Add new device for upload</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-sn">Serial Number </span>
          <input type="text" class="form-control" placeholder="Serial Number" aria-label="New device setup" aria-describedby="basic-addon2" value={devSN} onChange={e=>setDevSN(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-ssid">SSID </span>
          <input type="text" class="form-control" placeholder="UET-[SerialNumber]" aria-label="New device setup" aria-describedby="basic-addon2" value={ssid} onChange={e=>setSSID(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-mpwd">Password </span>
          <input type="text" class="form-control" placeholder="Password" aria-label="New device setup" aria-describedby="basic-addon2" value={devMPW} onChange={e=>setDevMPW(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-nametag">Name Tag </span>
          <input type="text" class="form-control" placeholder="Name Tag" aria-label="New device setup" aria-describedby="basic-addon2" value={devName} onChange={e=>setDevName(e.target.value)}/>
        </div>
        <div class="input-group mb-3">
            <span class="input-group-text" id="basic-team">Team </span>
            <TeamSelector teams={teams} data={data} setData={setData} />
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-desc">Description </span>
          <input type="text" class="form-control" placeholder="Description" aria-label="New device setup" aria-describedby="basic-addon2" value={devDesc} onChange={e=>setDevDesc(e.target.value)}/>
        </div>
        <div class="input-group mb-3 align-items-center">
          <span class="input-group-text me-3" id="basic-desc">Locked </span>
          <input class="form-check-input" type="checkbox" aria-label="New device setup" aria-describedby="basic-addon2" value={locked} onChange={()=>setLocked(!locked)} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>Submit</Button>
        <Button variant="secondary" onClick={onHide}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}
