import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { dvSrv } from "../App"
import { Link } from "react-router-dom"
import { SessionContext } from "../App"
import { useTeamAndAllUsers } from "../hook/DataProvider"
import { TimeStringForTimeZone } from "./Setting"
import ReactJson from '@microlink/react-json-view';

export const Tasks = () => {
  const {data} = useContext(SessionContext)

  // const [teamTree, setTeamTree] = useState({})
  const [teams,users] = useTeamAndAllUsers()

  const [flag, setFlag] = useState(false)
  const refresh = () => setFlag(!flag)

  if (data)
  return (
    <>
    <div class="row">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active internal-tab" id="my-tasks-tab" data-bs-toggle="tab" data-bs-target="#mytasks" type="button" 
          role="tab" aria-controls="mytasks" aria-selected="false">My Tasks{<TaskAlert flag={flag}/>}</button>
        </li>
        { (data.IsAdmin || data.IsRoot) && <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="team-tasks-tab" data-bs-toggle="tab" data-bs-target="#teamtasks" type="button" role="tab" aria-controls="teamtasks" aria-selected="false">Team Tasks</button>
        </li>}
        { data.IsRoot && <li class="nav-item" role="presentation">
          <button class="nav-link internal-tab" id="all-tasks-tab" data-bs-toggle="tab" data-bs-target="#alltasks" type="button" role="tab" aria-controls="alltasks" aria-selected="false">All Tasks</button>
        </li> }

      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="mytasks" role="tabpanel" aria-labelledby="my-tasks-tab">
          <TaskGroup category={"self"} taskChanged={refresh} users={users}/>
        </div>
        <div class="tab-pane fade" id="teamtasks" role="tabpanel" aria-labelledby="team-task-tab">
          <TaskGroup category={"team"} taskChanged={refresh} users={users}/>
        </div>
        <div class="tab-pane fade" id="alltasks" role="tabpanel" aria-labelledby="all-tasks-tab">
          <TaskGroup category={"all"} taskChanged={refresh} users={users}/>
        </div>
      </div>
    </div>
    {/* <ReactJson src={users} collapsed={1} /> */}
    </>
  )
}

export const TaskGroup = ({category, taskChanged, users}) => {
  const session = useContext(SessionContext)
  //const [teams, users] = useTeamAndUser()
  const [data, setData] = useState([])
  const [flag, setFlag] = useState(false)

  const refresh = () => {
    taskChanged()
    setFlag(!flag)
  }

  useEffect(() => {
    axios.get(dvSrv + `/api/task/`+category, {withCredentials: true})
    .then(({data}) => {
      setData(data)
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })}, [flag])

  return (
    <>
    <TaskTable refresh={refresh} tasks={data} users={users} isSelf={category === "self"}/>
    {/* <ReactJson src={data} collapsed="1" /> */}
    </>
  )
}

export const TaskTable = ({tasks, users, refresh, isSelf}) => {

  if (tasks)
  return (
    <table class="table table-hover">
    <thead>
      <th>Case Number</th>
      {/*!isSelf && */<th>Owner</th>}
      <th>Description</th>
      <th>Due Date</th>
      <th>Assigned By</th>
      <th>Done</th>
      {/* <th>Last Updated</th> */}
    </thead>
    <tbody>
      {tasks.sort((a,b) => {
          return a.DueDate - b.DueDate
        }).map(task => <TaskTab task={task} refresh={refresh} users={users} isSelf={isSelf}/>)}
    </tbody>
  </table>
  )
}

export const TaskTab = ({task, refresh, users, isSelf}) => {
    //const [data, setData] = useState(task)
    const session = useContext(SessionContext)
    const [done, setDone] = useState(task.Done)
  
    useEffect(() => {
      //setData(task)
      setDone(task.Done)
    }, [task])

    const handleDoneChange = (e) => {
      //setData({...data, ["Done"]: e.target.checked})
      
      axios.post(dvSrv + `/api/task/${task.ID}`, {Done: e.target.checked}, {withCredentials: true})
      .then(({data}) => {
        setDone(e.target.checked)
        refresh()
      })
      .catch(error => { console.error("Error: ", error) } )
    }
    
    const owner = task /*&& !isSelf*/ && users && users.find((item) => item.ID === task.UserID)
  
    if (task)
    return (
      <>
      <tr>
        <td><Link to={`/case/${task.CaseNumber}`}>{task.CaseNumber}</Link></td>
        {/* <td>{task.CaseNumber}</td> */}
        {/*!isSelf && */owner && <td>{owner.username}</td> }
        <td>{task.Description}</td>
        <td>{ TimeStringForTimeZone(session, task.DueDate)}</td>
        <td>{task.AssignedBy}
          {/* {sender ? <span>{sender.FirstName} {sender.LastName}</span> : null} */}
        </td>
        <td class="text-center">
            <input class="form-check-input" type="checkbox"
              checked={done} onChange={handleDoneChange}/>
          </td>
      </tr>
      {/* <ReactJson src={{owner, task, users}} /> */}
      </>
    )
}

export const TaskAlert = (flag) => {
  const session = useContext(SessionContext)
  const [tasks, setTasks] = useState([])
  //const [flag, setFlag] = useState(false)

  //const refresh = () => setFlag(!flag)

  useEffect(() => {
    axios.get(dvSrv + `/api/task/self/active`, {withCredentials: true})
    .then(({data}) => {
      setTasks(data)
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })}, [flag])

  //refresh()

  if (session && session.data && tasks.length > 0 )
    return (
        <span class="badge rounded-pill bg-danger ms-1">{tasks.length}</span>
    )
}