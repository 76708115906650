import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { toast } from 'react-toastify'

import axios from 'axios'
import { dvSrv } from "../App"
import { isS3PresignedURL } from './Player'

export const ReportImage = forwardRef(({ caseNum, refresh }, ref) => {
    const [imageSrc, setImageSrc] = useState(null)
    const [imageFile, setImageFile] = useState(null)
    const [imageUrl, setImageUrl] = useState("")
    const fileInputRef = useRef(null)

      // Update imageSrc whenever imageUrl changes
    useEffect(() => {
        if (caseNum === "") return

        axios.get(dvSrv + `/api/report/${caseNum}/imageurl`, {withCredentials: true})
        .then(({data}) => {
          if (!data || data === "") {
            setImageUrl("")
            return
          }
          setImageUrl(data)
          let fullurl = isS3PresignedURL(data) ? data: (dvSrv + data)
          setImageSrc(fullurl)
          console.log('Setting report image url to: ', fullurl);
        })
        .catch(error => console.error("Error fetching report image url: ", error));
    }, [caseNum])
    
    // Expose methods to the parent component via ref
    useImperativeHandle(ref, () => ({
        //getImageFile: () => imageFile,
        //getImageSrc: () => imageSrc,
        saveImageChange: () => {
            handleSaveImageChange()
          },
    }))

    // console.log("imageUrl: ", imageUrl)
    const MAX_IMG_SIZE = 5 * 1024 * 1024; // 5MB in bytes

    const handlePaste = (event) => {
        const clipboardItems = event.clipboardData.items
        for (let i = 0; i < clipboardItems.length; i++) {
        const item = clipboardItems[i]
        if (item.type.startsWith("image/")) {
            const file = item.getAsFile()
            if (file.size > MAX_IMG_SIZE) {
                alert("Image size exceeds 5MB. Please paste a smaller image.");
                return;
            }
            const url = URL.createObjectURL(file)
            setImageSrc(url)
            setImageFile(file) // Save the file for upload
            break
        }
        }
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0]
        if (file){
            if (file.size > MAX_IMG_SIZE) {
                alert("Image size exceeds 5MB. Please paste a smaller image.");
                return;
            }
            setImageFile(file)
            setImageSrc(URL.createObjectURL(file)) // Preview the image locally        
        }
    }
    
    const handleSaveImageChange = () =>{
        if (imageFile) {
          // attach new image (will replace existing attached image)
          const formData = new FormData();
          formData.append("image", imageFile)
  
          // Upload image
          axios.post(dvSrv + `/api/report/${caseNum}/image`, formData, {withCredentials: true})
          .then(response => {
            const imgurl = response.data.imageUrl
            setImageUrl(imgurl)
            setImageFile(null)
            refresh()
            toast.success("Image is saved!")
          })
        } else {
          //const imageSrc = reportImageRef.current.getImageSrc()
          //console.log("imageUrl: ", imageUrl)
          //console.log("Image src: ", imageSrc)
          if (caseNum && caseNum !== "" && imageUrl !== "" && imageSrc === null){
            // confirm deletion of existing attached image
            if (window.confirm("Are you sure to remove attached image?")){
              axios.delete(dvSrv + `/api/report/${caseNum}/image`, {withCredentials: true})
              .then(response => {
                //console.log("DELETE reponse.data: ", response.data)
                setImageUrl(response.data.imageUrl) // should be ""
                refresh()
                toast.success("Image is deleted!")
              })
            }
          }
        }
    }

    const clearImage = (event) => {
        setImageFile(null)
        setImageSrc(null) // clear the image locally
        // Clear the file input value
        fileInputRef.current.value = ""
    }

  return (
    <div class="mb-2"
      style={{
        border: "2px dashed gray",
        padding: "5px",
        // textAlign: "center",
        // width: "300px",
      }}
      onPaste={handlePaste}
    >
        <div class="input-group row align-items-center">
        <span class="col-sm-5 me-1 fs-6">Attach or paste an image (&lt;5MB)</span>
            {/* <label for="imageFileInput">Select an image file or Paste an image here</label> */}
            <input type="file" class="form-control col-sm-6 me-1" id="imageFileInput" 
                ref={fileInputRef} accept="image/*" onChange={handleImageChange} />
            <button class="col-sm-1" onClick={clearImage} disabled={!imageSrc}><FontAwesomeIcon icon={faTrashCan}/></button>
        </div>
      
      
      {imageSrc && <img src={imageSrc} alt="Attached Image" style={{ maxWidth: "100%" }} />}
      
      {/* <button onClick={handleUpload} disabled={!imageFile}>
        Upload Image
      </button> */}
    </div>
  )
})
