import './App.css';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { SignIn, ForgotCredential } from './Signin';
import { Users, User, UserProfile } from './model/User';
import { TeamDataTree } from './model/Team';
import { Accounts } from './model/Account';
import { MainLayoutV1 } from './layout/MainLayoutV1';
import { SignUp, VerifyEmail } from './Signup';
import { Home } from './component/Home';
import { Settings, UserSettings } from './model/Setting';
import { NotificationBoard } from './component/Dashboard';
import { MyVideos } from './model/Video';
import { UserTutorial } from './component/Tutorial';
import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Case } from './model/Case';
import { MyPreloaded } from './Upload';
import { MFAVerify } from './MFA';
import { Reports } from './model/Report';
import { SSOSignIn, SSOVerify } from './SSO';
import { Tasks } from './model/Task';
import { MySubscriptions } from './model/Subscription';
import { Plans, Subscribe } from './model/Plan';
import { Billings } from './model/Billing';
import MainLayoutV2 from './layout';
import { Invoices } from './model/Invoice';
import moment from "moment-timezone";
import { toast } from "react-toastify"

export const SessionContext = createContext()
export const dvSrv = process.env.REACT_APP_DV_API_URL 
  || `http://localhost:${process.env.REACT_APP_DV_API_PORT}`
export const enableStripe = `${process.env.REACT_APP_ENABLE_STRIPE}` === "true"
export const newMobileAppReleasedAlert = () => {
  const fixedDate = "2024-12-21"
  const currentDate = moment() // Get the current date and time
  const fixedMoment = moment.tz(fixedDate, "YYYY-MM-DD", "America/New_York")// Parse fixed date in Eastern timezone

  if (currentDate.isBefore(fixedMoment)) {
    toast.info("For UE Scope users, new version 1.2.2 of UE Transfer iOS app is released on Dec 2 2024!")
  }
}

export default function App() {

  const [data, setData] = useState({})
  const session = {data, setData}

  useEffect(() => {
    axios.get(dvSrv+"/api/checktoken", {withCredentials: true})
    .then(({data}) => setData(data))
    .catch(error => {
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })}, [])
  
  return (
    <BrowserRouter>
      <SessionContext.Provider value={session}>
        <Routes>
          <Route element={<MainLayoutV2 />}>
            <Route path="/" element={<Home />} />
            <Route path='/disclaimer' element={<Home/>} />
            <Route path='/privacy' element={<Home/>} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signin/mfa" element={<MFAVerify />} />
            <Route path="/signin/forgot" element={<ForgotCredential />} />
            <Route path="/sso" element={<SSOSignIn />} />
            <Route path="/sso/verify" element={<SSOVerify />} />
            {/* <Route path="/signup" element={<SignUp />} /> */}
            <Route path="/verifyemail" element={<VerifyEmail />} />
            <Route path="/dashboard/" element={<ProtectedRoute element={<NotificationBoard />} />} />
            <Route path="/team/" element={<ProtectedRoute element={<TeamDataTree />} />} />
            <Route path="/team/admin" element={<RootOnlyRoute element={<TeamDataTree />} />} />
            <Route path="/team/:id" element={<ProtectedRoute element={<TeamDataTree />} />} />
            <Route path="/user/" element={<RootOnlyRoute element={<Users />} />} />
            <Route path="/user/:id" element={<ProtectedRoute element={<User />} />} />
            <Route path="/setting" element={<RootOnlyRoute element={<Settings />} />} />
            <Route path="/profile" element={<ProtectedRoute element={<UserProfile />} />} />
            <Route path="/setting/user" element={<ProtectedRoute element={<UserSettings />} />} />
            <Route path="/case/:id" element={<ProtectedRoute element={<Case />} />} />
            <Route path="/video/" element={<ProtectedRoute element={<MyVideos />} />} />
            {/* <Route path="/video/drafted" element={<ProtectedRoute element={<MyDrafted />} />} /> */}
            <Route path="/report/" element={<RootOnlyRoute element={<Reports />} />} />            
            {/* <Route path="/report/:id" element={<ProtectedRoute element={<ReviewVideoByCase />} />} /> */}
            <Route path="/tutorial" element={<ProtectedRoute element={<UserTutorial />} />} />
            <Route path="/upload" element={<ProtectedRoute element={<MyPreloaded />} />} />
            <Route path="/task/" element={<ProtectedRoute element={<Tasks />} />} />

            {/* { enableStripe && <Route path="/billing" element={<RootOnlyRoute element={<Billings />} />} />}
            { enableStripe && <Route path="/plan" element={<RootOnlyRoute element={<Plans />} />} />}
            { enableStripe && <Route path="/subscribe" element={<RootOnlyRoute element={<Subscribe />} />} />}
            { enableStripe && <Route path="/subscription" element={<RootOnlyRoute element={<MySubscriptions />} />} />} */}

            <Route path="/account/" element={<DeepvinOnlyRoute element={<Accounts />} />} />
            <Route path="/account/:uuid/team" element={<DeepvinOnlyRoute element={<TeamDataTree />} />} />
            <Route path="/invoice/" element={<DeepvinOnlyRoute element={<Invoices />} />} />

          </Route>
        </Routes>
      </SessionContext.Provider>
    </BrowserRouter>
  )
}

export const ProtectedRoute = ({ element }) => {
  const session = useContext(SessionContext)
  const location = useLocation()
  return session.data 
  ? (element || <Outlet />) 
  : (<Navigate to="/signin" state={{ from: location }} replace />)
}

export const RootOnlyRoute = ({ element }) => {
  const session = useContext(SessionContext)
  const location = useLocation()
  return session.data && session.data.IsRoot 
  ? (element || <Outlet />) 
  : (<Navigate to="/signin" state={{ from: location }} replace />)
}

export const DeepvinOnlyRoute = ({ element }) => {
  const session = useContext(SessionContext)
  const location = useLocation()
  return session.data && session.data.IsRoot && session.data.AccountID === 1
  ? (element || <Outlet />) 
  : (<Navigate to="/signin" state={{ from: location }} replace />)
}