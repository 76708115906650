import { useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { SessionContext, dvSrv } from "./App";
import { toast } from "react-toastify"
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip"
import { newMobileAppReleasedAlert } from "./App";
import ReactJson from "@microlink/react-json-view";

export const MFASetup = ({handleMFAResult}) => {
  const [mfaSecret, setMfaSecret] = useState('')
  const [email, setEmail] = useState('')
  const [errBar, setErrBar] = useState("")
  const [done, setDone] = useState(false)

  // get Authenticator secret Url from server to generate QR code,
  // then user can use Authentictor app to scan QR to register MFA
  const handleSubmit = () => {
    axios.post(dvSrv + "/api/mfa", {
      Email: email, 
    }, {withCredentials: true})
      .then(({data}) => {
        setMfaSecret(data)
        setErrBar("")
  //      setDone(true)
      })
      .catch((error) => {
        setMfaSecret("")
        setErrBar("Error: " + error)
        setDone(false)
        toast.error(error.response.data.error)
      })
  }

 const handleDone = () => {
    setDone(true)
    handleMFAResult(true)  
  }

  if(!done){
    return (
        <>
        {/* <h4 class="mt-5 mb-3">Please provide a backup email (required)</h4> */}
        <div>
             <div class="form-floating col-6">
              <input type="email" class="form-control mb-3" id="floatingEmail" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} />
              <label for="floatingEmail">Email address for MFA backup (required)</label>
            </div>
            
            {mfaSecret && <QRCode value={mfaSecret} />}
            {mfaSecret && <p style={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#00F' }}>Scan the QR code with your Google Authenticator app to set up MFA.</p>}

            <button class="btn btn-primary py-2" onClick={ !mfaSecret ? handleSubmit : handleDone }>{!mfaSecret ? "Submit" : "Done"}</button>
        </div>
        <hr />
        <p>{errBar && errBar}</p>
        {/* <ReactJson src={{email, passcode, newPassword, newPassword2}} /> */}
    </>
    )
  }
}

export const MFAVerify = () => {
  const [totp, setTotp] = useState('')
  const [useEmail, setUseEmail] = useState(false)
  const [errBar, setErrBar] = useState("")

  const location = useLocation();
  const navLink = location.state?.from?.pathname || "/dashboard/"
 
  const session = useContext(SessionContext)
  const navigate = useNavigate()
  const handleMFAVerify = async () => {
    axios.post(dvSrv + "/api/mfa/verify", {
        MFACode: totp,
        UseEmail: useEmail, 
      }, {withCredentials: true})
      .then(({data}) => {
        session.setData(data)
        navigate(navLink, { replace: true })
        newMobileAppReleasedAlert()
      })
      .catch((error) => {
        session.setData(null)
        setErrBar("Error: " + error)
        toast.error(error.response.data.error)
      })
    }

  const handleEmailCode= async () => {
    axios.get(dvSrv + "/api/mfa/email", {withCredentials: true})
      .then(({data}) => {
        setErrBar("")
        toast.success("MFA code was sent to registered email")
        //navigate("/dashboard/")
      })
      .catch((error) => {
        setErrBar("Error: " + error)
        toast.error(error.response.data.error)
      })
    }

  return (
    <>
    <div class="form-signin w-100 m-auto">
        {/* <img class="mb-4" src={logo} alt="" width="72" height="72" /> */}
        <h3 class="h3 mb-3 fw-normal">MFA Verification</h3>
    
        <div class="form-floating">
          <input type="text" class="form-control mb-3" id="floatingMFAcode" placeholder="123456" value={totp} onChange={e => setTotp(e.target.value)} />
          <label for="floatingMFAcode">MFA Code</label>
        </div>
        
        <div class="form-check text-start my-3">
          <input class="form-check-input" type="checkbox" id="flexCheckUseEmail" value={useEmail} onChange={()=>setUseEmail(!useEmail)} />
          <label class="form-check-label me-2" for="flexCheckUseEmail">
            Send code via Email
          </label>
          <button class="btn btn-outline-secondary py-0" id="btn-email-mfa" disabled={!useEmail}
            onClick={handleEmailCode}><FontAwesomeIcon icon={faCircleArrowRight}/></button>
        </div>
        
        <button class="btn btn-primary w-100 py-2" onClick={handleMFAVerify}>Submit</button>

        <p>{errBar && errBar}</p>
    </div>
    <Tooltip anchorSelect="#btn-email-mfa" place="right">Send code to registered MFA email</Tooltip>
    {/* <ReactJson src={{email, passcode}} /> */}
  </>
  )
}
